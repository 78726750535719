import * as React from 'react'
import {container} from './post-layout.module.css';
import ILayout from "../../interfaces/layout";
import Footer from "../footer/footer";
import Navigation from "../navigation/navigation";

const PostLayout = ({children}: Partial<ILayout>) => {
    return (
        <>
            <Navigation/>
            <div className={container}>
                <main>{children}</main>
            </div>
            <Footer/>
        </>
    )
}

export default PostLayout