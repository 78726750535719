import React from 'react'
import {graphql, Link} from 'gatsby'
import {MDXRenderer} from 'gatsby-plugin-mdx'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMugHot} from '@fortawesome/free-solid-svg-icons';

import PostLayout from "../components/post-layout/postLayout";
import {
    heroImage,
    socialHeader,
    metaData,
    date,
    readTime,
    postTile,
    navigationFooter,
    next,
    link,
    previous
} from '../pages/blog.module.css';
import Newsletter from "../components/news-letter/newsletter";
import Seo from "../components/seo";

const BlogPost = ({data}: any) => {

    const post = data.mdx;

    return (
        <PostLayout pageTitle={post.frontmatter.title}>

            <div className={socialHeader}>

                <div className={metaData}>
                    <h1 className={postTile}>{post.frontmatter.title}</h1>
                    <span className={date}> le {post.frontmatter.date}</span> &nbsp;
                    <span className={readTime}>
                         <FontAwesomeIcon icon={faMugHot} size="1x"/>&nbsp;
                        lecture {Math.round(post.fields.readingTime.minutes)}min
                    </span>
                </div>

            </div>

            <div className={heroImage}>
                <img src={post.frontmatter.hero_image.publicURL} alt={post.frontmatter.hero_image_alt}/>
            </div>

            <MDXRenderer localImages={post.frontmatter.embeddedImagesLocal}>
                {post.body}
            </MDXRenderer>

            <div className={navigationFooter}>

                <div className={previous}>
                    {data.previous && post?.slug !== data.previous?.slug && (
                        <div>
                            <Link className={link} to={`/${data.previous?.slug}`}>
                                {data.previous?.frontmatter?.title.substring(0, 35)}
                            </Link>
                        </div>
                    )}
                </div>

                <div className={next}>
                    {data.previous && post?.slug !== data.next?.slug &&(
                        <div>
                            <Link className={link} to={`/${data.next?.slug}`}>
                                {data.next?.frontmatter?.title.substring(0, 35)}
                            </Link>
                        </div>
                    )}
                </div>

            </div>

            <hr/>

            <Newsletter />

        </PostLayout>
    )
}

export const query = graphql`
    query ($id: String $previousPostId: String  $nextPostId: String) {
        mdx(id: {eq: $id}) {
             frontmatter {
                 date(formatString: "MMMM D, YYYY")
                 title
                 tag
                 description
                 hero_image_alt
                 hero_image_credit_link
                 hero_image_credit_text
                 hero_image {
                     publicURL
                 }
             }
             slug
             body
             fields {
                 readingTime {
                   minutes
                 }
             }
        }
        previous: mdx(id: { eq: $previousPostId }) {
            slug
            frontmatter {
            title
            }
        }
        next: mdx(id: { eq: $nextPostId }) {
            slug
            frontmatter {
            title
            }
        }
    }
 `

export default BlogPost

export const Head = ({data}: any) => {
    const post = data.mdx;
    return <Seo title={post.frontmatter.title} description={post.frontmatter.description} slug={post.slug} />
}